import React from 'react'
import { useHistory } from 'react-router'

import { Stack } from '@mui/material'
import { useVisibilityChange } from 'use-visibility-change'

import { TitleHeader } from 'Components/Blocks'

import { DELPHI } from 'Config'

import * as paths from 'Constants/paths'

import { MeteredPriceKind } from 'GraphQL/Main/TypedDocuments'

import { useBillingTracker } from 'Services/Billing'

import { ChatElement } from './styles'

function AITextPage() {
  const history = useHistory()

  useBillingTracker(MeteredPriceKind.Text)

  useVisibilityChange({
    shouldReturnResult: false,

    onHide: () => {
      history.replace(paths.AI)
    },
  })

  return (
    <Stack flexGrow={1}>
      <TitleHeader title="Chat" />

      <ChatElement
        allow="microphone"
        src={DELPHI.EMBED_URL}
        title="Text chat"
      />
    </Stack>
  )
}

export default AITextPage
