import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { ChatBubbleOutline, VideocamOutlined } from '@mui/icons-material'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import numbro from 'numbro'

import compact from 'lodash/compact'

import { AuthHeader } from 'Components/Blocks'

import { WeekBillingDocument } from 'GraphQL/Main/TypedDocuments'

import { SafeAreaView } from 'Services/SafeArea'

const PRICE_FORMAT_CONFIG = {
  spaceSeparated: false,
  mantissa: 2,
}

function SubscriptionUsagePage() {
  const { data } = useQuery(WeekBillingDocument)

  const values = useMemo(() => {
    if (!data?.weekBilling) return {}

    const chatBilled = data.weekBilling.textPaymentAmount / 100
    // const callBilled = data.weekBilling.audioPaymentAmount / 100
    const videoBilled = data.weekBilling.videoPaymentAmount / 100
    const billingWeekStart = DateTime.fromISO(data.weekBilling.startDate)
    const billingWeekEnd = DateTime.fromISO(data.weekBilling.endDate)
    const billingWeekStartMonth = billingWeekStart.toFormat('LLLL')
    const billingWeekEndMonth = billingWeekEnd.toFormat('LLLL')
    const billingMonths = compact([
      billingWeekStartMonth,
      billingWeekStartMonth !== billingWeekEndMonth && billingWeekEndMonth,
    ]).join('-')
    const billingDays = [
      billingWeekStart.toFormat('d'),
      billingWeekEnd.toFormat('d'),
    ].join('-')

    return {
      chatBilled,
      // callBilled,
      videoBilled,
      totalBilled: chatBilled + videoBilled,
      // TODO: Return callBilled into the equation after delphi solves the problem
      //       with voice api
      // totalBilled: chatBilled + callBilled + videoBilled,
      billingWeek: ['Week', billingMonths, billingDays].join(' '),
      billingWeekStartDate: billingWeekStart.toFormat('cccc'),
    }
  }, [data])

  return (
    <Stack flexGrow={1}>
      <AuthHeader />

      <Box px={4}>
        <Typography align="center" variant="h5">
          Subscription plan usage
        </Typography>

        <Typography align="center" color="text.secondary" mt={1.5}>
          Thank you for using the digital twin. Below you will find your usage
          in real-time
        </Typography>
      </Box>

      {data && (
        <>
          <Stack flexGrow={1} justifyContent="center">
            <Typography align="center" variant="h3">
              {numbro(values.totalBilled).formatCurrency(PRICE_FORMAT_CONFIG)}
            </Typography>

            <Typography align="center" color="text.secondary" variant="caption">
              {values.billingWeek}
            </Typography>
          </Stack>

          <Stack pb={2} px={2} spacing={5}>
            <Stack spacing={2}>
              <Stack alignItems="center" direction="row" spacing={1.5}>
                <ChatBubbleOutline
                  sx={{ fontSize: 20, color: 'text.secondary' }}
                />
                <Typography variant="subtitle1">Сhat</Typography>
                <Divider sx={{ flexGrow: 1, pt: 1.5 }} />
                <Typography color="text.secondary">
                  {numbro(values.chatBilled).formatCurrency(
                    PRICE_FORMAT_CONFIG,
                  )}
                </Typography>
              </Stack>

              {/* <Stack alignItems="center" direction="row" spacing={1.5}>
                <Voicemail sx={{ fontSize: 20, color: 'text.secondary' }} />
                <Typography variant="subtitle1">Call</Typography>
                <Divider sx={{ flexGrow: 1, pt: 1.5 }} />
                <Typography color="text.secondary">
                  {numbro(values.callBilled).formatCurrency(
                    PRICE_FORMAT_CONFIG,
                  )}
                </Typography>
              </Stack> */}

              <Stack alignItems="center" direction="row" spacing={1.5}>
                <VideocamOutlined
                  sx={{ fontSize: 20, color: 'text.secondary' }}
                />
                <Typography variant="subtitle1">Video</Typography>
                <Divider sx={{ flexGrow: 1, pt: 1.5 }} />
                <Typography color="text.secondary">
                  {numbro(values.videoBilled).formatCurrency(
                    PRICE_FORMAT_CONFIG,
                  )}
                </Typography>
              </Stack>
            </Stack>

            <Typography align="center" color="text.secondary" variant="caption">
              We reset your usage on a weekly basis and charge your credit card
              on file every {values.billingWeekStartDate}
            </Typography>
          </Stack>
        </>
      )}

      <SafeAreaView bottom />
    </Stack>
  )
}

export default SubscriptionUsagePage
