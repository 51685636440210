import { useInterval } from 'react-use'

import { useMutation } from '@apollo/client'
import Utils from 'Utils'

import {
  AddBillingTimeDocument,
  MeteredPriceKind,
} from 'GraphQL/Main/TypedDocuments'

const TICK_RATE_IN_SECONDS = 10

// NOTE: Grab these values from the backend/stripe to keep them actual
const PRICE_PER_MINUTE = {
  CHAT: 1,
  CALL: 1.25,
  VIDEO: 1.5,
}

type UseBillingTrackerOptions = {
  paused?: boolean
}

// TODO: Implement more sophisticated tracking per kind on the global level
function useBillingTracker(
  kind: MeteredPriceKind,
  { paused }: UseBillingTrackerOptions = {},
) {
  const [addBillingTime] = useMutation(AddBillingTimeDocument)

  useInterval(
    async () => {
      try {
        addBillingTime({
          variables: {
            billings: {
              id: Utils.String.uuidv4(),
              duration: TICK_RATE_IN_SECONDS,
              meteredPriceKind: kind,
            },
          },
        })
      } catch (error) {
        //
      }
    },
    paused ? null : TICK_RATE_IN_SECONDS * 1000,
  )

  return null
}

export { PRICE_PER_MINUTE, useBillingTracker }
